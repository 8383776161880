import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

interface TextInputProps {
  predefinedValue?: string;
  label: string;
  name: string;
  error?: string;
  invalid?: boolean;
  required?: boolean;
  optional?: boolean;
  description?: string;
  handleChange?: (e: string | number) => void;
  highlightedSelection?: boolean;
  hideLabel?: boolean;
  shouldVisuallyValidate?: boolean;
  id?: string;
  underReview?: boolean;
  dateMask?: boolean;
  readOnly?: boolean;
  requiredLabel?: string;
}

function TextInput({
  readOnly = false,
  dateMask = false,
  handleChange = () => {},
  predefinedValue = "",
  label,
  className,
  name,
  invalid,
  error,
  required = false,
  optional = false,
  description = "",
  hideLabel = false,
  shouldVisuallyValidate = true,
  highlightedSelection = false,
  id = "text",
  requiredLabel = "",
  underReview = false,
  ...props
}: TextInputProps & React.HTMLAttributes<HTMLInputElement>) {
  const [value, setValue] = useState(predefinedValue);
  const invalidCondition = [invalid, error].some(Boolean);
  let { t } = useTranslation("general");

  function handleChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
    handleChange(event.target.value);
  }

  useEffect(() => {
    setValue(predefinedValue);
  }, [predefinedValue?.length]);

  return (
    <div className={classnames(className)} {...props}>
      <div>
        <div className={"flex content-center items-center"}>
          <label
            className={classnames({ "visually-hidden": hideLabel })}
            htmlFor={id}
            data-testid={`${id}-label`}
          >
            {readOnly ? (
              <>
                <span className={"visually-hidden"}>Read Only</span>
                {label}
              </>
            ) : (
              label
            )}
          </label>
          {required && (
            <span
              data-testid={`${label}-required`}
              className={"typography-3xs-semibold mb-1 ml-1 text-black050"}
            >
              {requiredLabel || t("labels.required")}
            </span>
          )}
          {optional && (
            <span className={"typography-3xs-semibold ml-1 mb-1 text-black050"}>
              {t("labels.optional")}
            </span>
          )}
        </div>
        {!!description && (
          <p className={"typography-xs mb-2 text-black100"}>{description}</p>
        )}
        <input
          aria-readonly={readOnly}
          readOnly={readOnly}
          className={classnames(
            {
              invalid: invalidCondition && shouldVisuallyValidate,
              "!border-pink150": highlightedSelection,
            },
            "typography-xs-medium py-3 px-2 ",
            "outline-none focus:border-2 focus:border-black050"
          )}
          id={id}
          type={"text"}
          value={value}
          onChange={handleChangeInput}
          name={name}
          placeholder={props.placeholder || (dateMask ? "mm/dd/yyyy" : "")}
          onKeyUp={(e) => {
            if (dateMask) {
              let value = e.currentTarget.value;
              if (value.match(/^\d{2}$/) !== null && e.which !== 8) {
                setValue(value + "/");
                handleChange(value + "/");
              } else if (
                value.match(/^\d{2}\/\d{2}$/) !== null &&
                e.which !== 8
              ) {
                setValue(value + "/");
                handleChange(value + "/");
              }
            }
          }}
          maxLength={dateMask ? 10 : 524288}
        />
      </div>
      {underReview && (
        <div>
          <span role="status" aria-live="polite" className="visually-hidden">
            {t("labels.under-review-expanded")}
          </span>
          <p
            data-testid={`${name}-review`}
            className="typography-3xs-semibold mt-1 w-[11rem] text-pink150"
          >
            {t("labels.under-review")}
          </p>
        </div>
      )}

      {error && (
        <div>
          <span role="status" aria-live="polite" className="visually-hidden">
            {`${t("labels.error")} ${error}`}
          </span>
          <p
            data-testid={`${name}-error`}
            className="typography-3xs-semibold mt-1 text-red100"
          >
            {error}
          </p>
        </div>
      )}
    </div>
  );
}

export default TextInput;
